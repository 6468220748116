import React from "react";
import PropTypes, { object } from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import {
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBIcon,
	MDBCardText,
} from "mdbreact";
import { Link } from "gatsby";

import { GGgetMode } from "../../services/gglocalstorage";

import InnerLayout from "../../Layouts/InnerLayout/InnerLayout";
import CardCoordenador from "../../components/CardCoordenador/CardCoordenador";
import BadgeSelected from "../../components/BadgeSelected/BadgeSelected";
import ButtonCTA from "../../components/ButtonCTA/ButtonCTA";
import SEO from "../../components/SEO/Seo";
import { ValidateHashPath } from "../../services/hashpathservice";
import SimuladorFinanceiro from "../../components/SimuladorFinanceiro/SimuladorFinanceiro";
import { ConvertModalidadeToDisplay } from "../../helpers/Formatters";

export default function CursoHomePage({
	pageContext: {
		curso,
		diferenciais,
		projetos,
		listaGrupoCursos,
		coordenacoes,
		perfilDoEgresso,
	},
}) {
	CursoHomePage.propTypes = {
		pageContext: PropTypes.object.isRequired,
		diferenciais: PropTypes.arrayOf(object),
		projetos: PropTypes.arrayOf(object),
		curso: PropTypes.object.isRequired, // array de cursos
		listaGrupoCursos: PropTypes.object,
		coordenacoes: PropTypes.object,
		perfilDoEgresso: PropTypes.arrayOf(object),
	};

	let selectedIndex =
		typeof window !== "undefined"
			? ValidateHashPath(curso.Items.length, location.hash)
			: 0;
	let cursoSelecionado = curso.Items[selectedIndex];

	// Argumentos Way
	let proposito = cursoSelecionado?.Argumentos?.Proposito?.Value;
	let processo = cursoSelecionado?.Argumentos?.Processo?.Value;
	let resultado = cursoSelecionado?.Argumentos?.Resultado?.Value;

	// diferenciais = cursoSelecionado?.diferenciais[0]?.cursoDiferenciais;

	let diferenciaisGerenciador =
		cursoSelecionado.Argumentos?.Diferenciais?.Value;
	let internacionalizacao =
		cursoSelecionado?.Argumentos?.Internacionalizacao?.Value;
	let parceirosDeMercado =
		cursoSelecionado?.Argumentos?.ParceirosDeMercado?.Value;

	// Conteudo adicional
	let ConteudoAdicional =
		cursoSelecionado?.Informacoes?.ConteudoAdicional?.Value;
	// Variaveis para Perfil do egresso
	let categorias = perfilDoEgresso[0][0]?.cursoPerfilDoEgressoCategoria;
	let habilidades = perfilDoEgresso[0][0]?.cursoPerfilDoEgressoHabilidade;

	let titulo = cursoSelecionado.Title;

	// Identificador de index selecionado no innerlayout
	let hash = typeof window !== "undefined" ? location.hash : "#";

	const flex = () =>{
		if (cursoSelecionado.Informacoes?.Modalidade?.Value === "Hibrido") {
			return "Presencial";
		  } else {
			return cursoSelecionado.Informacoes?.Modalidade?.Value;
		  }
	}


	function getValor() {
		let campoInvestimento = cursoSelecionado.Informacoes?.Investimento?.Value;
		// Caso o curso tenha informações de investimento registradas.
		if (campoInvestimento !== null && !(campoInvestimento === "")) {
			let htmlValores;
			if (GGgetMode()) {
				htmlValores = campoInvestimento
					.replaceAll("text-primary", "text-secondary")
					.replaceAll("bg-primary", "bg-white")
					.replaceAll("white-text", "text-dark");
			} else {
				htmlValores = campoInvestimento;
			}

			return (
				<div
					className={GGgetMode() && "text-white"}
					dangerouslySetInnerHTML={{
						__html: htmlValores,
					}}
				></div>
			);
		}

		// Caso seja EAD ou Semipresencial, utiliza a tabela de modalidades.
		if (
			cursoSelecionado.Informacoes?.Modalidade?.Value === "EAD" ||
			cursoSelecionado.Informacoes?.Modalidade?.Value === "Semipresencial"
		) {
			return (
				<>
					<MDBRow className="no-gutters">
						<MDBCol
							xs="12"
							md="6"
							className="d-flex align-items-center text-left justify-content-center no-gutters"
						>
							<MDBCard
								className={
									"bg-secondary p-3"
								}
							>
								<MDBCardBody>
									<MDBCardText className="white-text text-center">
										<MDBRow>
											<MDBCol col={2} className="mt-auto mb-auto">
												<MDBIcon icon="dollar-sign" size="5x" />
											</MDBCol>
											<MDBCol col={10} className="mt-auto mb-auto">
												<div className="price header text-nowrap">
													<h2 className="number">
														{titulo ===
														"Educação Física - Bacharelado - EAD"
															? "R$ 554,62" // Valor curso semipresencial
															: [
																	{
																		modalidade: "Licenciatura",
																		valor: "R$ 446,46",
																	},
																	{
																		modalidade: "Tecnologo",
																		valor: "R$ 436,23",
																	},
																	{
																		modalidade: "Bacharelado",
																		valor: "R$ 487,55",
																	},
															  ].find(
																	(i) =>
																		i.modalidade ===
																		cursoSelecionado.Informacoes?.Tipo?.Value
															  ).valor}
													</h2>
													<h5 className="mb-0">cada mensalidade</h5>
												</div>
											</MDBCol>
										</MDBRow>
									</MDBCardText>
								</MDBCardBody>
							</MDBCard>
						</MDBCol>
						<MDBCol
							xs="12"
							md="6"
							className={
								(GGgetMode() && "white-text") +
								" d-flex mt-4 align-items-center text-left justify-content-center no-gutters"
							}
						>
							<p>
								Valor mensal e referente ao total de componentes curriculares
								que compõem cada módulo.
								<br />
								Cada trimestre é composto por três mensalidades.
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow className={(GGgetMode() && "white-text") + " mt-4"}>
						<MDBCol>
							<p>
								Obs.: Os valores das mensalidades podem sofrer reajustes anuais, conforme índices praticados pela instituição.
							</p>
						</MDBCol>
					</MDBRow>
				</>
			);
		}

		
		// Caso utilize o simulador financeiro
		//return <SimuladorFinanceiro />;
		return (
			<div className="text-center px-5 mx-5">
				<h1 className="text-primary">Investimento</h1>
				<MDBBtn
					className="btn-white btn-outline-primary font-weight-bold d-block mx-5 my-5"
					href="https://www.feevale.br/institucional/ingresso/beneficios#simuladorFinanceiro"
					target="_blank"
				>
					Saiba mais
				</MDBBtn>
			</div>
		);
	}

	return (
		<InnerLayout curso={curso} listaGrupoCursos={listaGrupoCursos}>
			<Container>
				<SEO
					title={curso.Title}
					description={curso.Items[0].Argumentos.Proposito.Value}
				/>

				{cursoSelecionado?.Informacoes?.Aviso?.Value && (
					<Row>
						<Col>
							<div
								className={`text-center font-weight-bold bg-secondary rounded p-3 mb-4 ${
									!GGgetMode() && "text-white"
								}`}
								dangerouslySetInnerHTML={{
									__html: cursoSelecionado?.Informacoes?.Aviso?.Value,
								}}
							/>
						</Col>
					</Row>
				)}
				<Row className="my-2 text-center text-md-left">
					<Col md="8">
						<h2
							className={
								(GGgetMode() ? "text-white" : "text-secondary") +
								" font-weight-bold"
							}
						>
							{cursoSelecionado.Informacoes.Tipo.Value.replace("Tecnologo", "Tecnólogo") +
								" - " +
								flex()}
						</h2>
						<div>
							{proposito && (
								<>
									<h4 className="text-secondary">
										{
										 !isNaN(parseInt(cursoSelecionado.Integracao.CodComposicao.Value.charAt(0)))
										 || cursoSelecionado.Title === "Odontologia - Bacharelado - Flex"
										 ? "Proposito" : "Objetivo"}
									</h4>
									<p
										className={GGgetMode() && "text-white"}
										dangerouslySetInnerHTML={{
											__html: proposito,
										}}
									/>
								</>
							)}
							{processo && (
								<>
									<h4 className="text-secondary">{
										 !isNaN(parseInt(cursoSelecionado.Integracao.CodComposicao.Value.charAt(0)))
										 || cursoSelecionado.Title === "Odontologia - Bacharelado - Flex"
										  ? "Processo" : "Modelo do Curso"}</h4>
									<p
										className={GGgetMode() && "text-white"}
										dangerouslySetInnerHTML={{
											__html: processo,
										}}
									/>
								</>
							)}
							{resultado && (
								<>
									<h4 className="text-secondary">Resultado</h4>
									<p
										className={GGgetMode() && "text-white"}
										dangerouslySetInnerHTML={{
											__html: resultado,
										}}
									/>
								</>
							)}
						</div>
						<CardCoordenador coordenadorSelecionado={coordenacoes[0]} />
					</Col>
					<Col>
						<Row>
							<Col className="order-2 order-md-1">
								<Row className="pt-2 pt-md-0">
									<Col>
										<MDBCard className="bg-secondary">
											<MDBCardBody
												className={
													GGgetMode()
														? "border border-secondary p-4 rounded"
														: "rounded"
												}
											>
												<Row className="p-0 m-0">
													<Col className="col-2 p-0 m-0 text-center">
														<MDBIcon
															icon={
																"hourglass-half " +
																(GGgetMode()
																	? "text-secondary"
																	: "text-primary") +
																"  p-0 m-0"
															}
															size="3x"
														/>
													</Col>
													<Col className="pl-5 p-0 m-0 mt-auto mb-auto text-left">
														<h5
															className={
																(GGgetMode()
																	? "text-secondary "
																	: "text-primary") + " p-0 m-0"
															}
														>
															Duração
														</h5>
														<p
															className={
																"text-primary p-0 m-0"
															}
														>
															{cursoSelecionado.Informacoes.Duracao.Value}
														</p>
													</Col>
												</Row>
											</MDBCardBody>
										</MDBCard>
									</Col>
								</Row>
								<Row className="pt-2">
									<Col>
										<MDBCard className="bg-secondary">
											<MDBCardBody
												className={
													GGgetMode()
														? "border border-secondary p-4 rounded"
														: "rounded"
												}
											>
												<Row className="p-0 m-0">
													<Col className="col-2 p-0 m-0 text-center">
														<MDBIcon
															icon={
																"chalkboard " +
																(GGgetMode()
																	? "text-secondary"
																	: "text-primary") +
																"  p-0 m-0"
															}
															size="3x"
														/>
													</Col>
													<Col className="pl-5 p-0 m-0 mt-auto mb-auto text-left">
														<h5
															className={
																(GGgetMode()
																	? "text-secondary "
																	: "text-primary") + " p-0 m-0"
															}
														>
															Modalidade
														</h5>
														<p
															className={
																"text-primary p-0 m-0"
															}
														>
															{cursoSelecionado.Informacoes?.Modalidade?.Value === "Hibrido"
																? "Presencial"
																: cursoSelecionado.Informacoes?.Modalidade?.Value}
														</p>
													</Col>
												</Row>
											</MDBCardBody>
										</MDBCard>
									</Col>
								</Row>
								<Row className="pt-2">
									<Col>
										<MDBCard className="bg-secondary">
											<MDBCardBody
												className={
													GGgetMode()
														? "border border-secondary p-4 rounded"
														: "rounded"
												}
											>
												<Row className="p-0 m-0">
													<Col className="col-2 p-0 m-0 text-center">
														<MDBIcon
															icon={
																"user-graduate " +
																(GGgetMode()
																	? "text-secondary"
																	: "text-primary") +
																"  p-0 m-0"
															}
															size="3x"
														/>
													</Col>
													<Col className="pl-5 p-0 m-0 mt-auto mb-auto text-left">
														<h5
															className={
																(GGgetMode()
																	? "text-secondary "
																	: "text-primary") + " p-0 m-0"
															}
														>
															Formação
														</h5>
														<p
															className={
																"text-primary p-0 m-0"
															}
														>
															{cursoSelecionado.Informacoes?.Tipo?.Value}
														</p>
													</Col>
												</Row>
											</MDBCardBody>
										</MDBCard>
									</Col>
								</Row>
								<Row className="pt-2">
									<Col>
										<MDBCard className="bg-secondary">
											<MDBCardBody
												className={
													GGgetMode()
														? "border border-secondary p-4 rounded"
														: "rounded"
												}
											>
												<Row className="p-0 m-0">
													<Col className="col-2 p-0 m-0 text-center">
														<MDBIcon
															icon={
																"map-marker-alt " +
																(GGgetMode()
																	? "text-secondary"
																	: "text-primary") +
																"  p-0 m-0"
															}
															size="3x"
														/>
													</Col>
													<Col className="pl-5 p-0 m-0 mt-auto mb-auto text-left">
														<h5
															className={
																(GGgetMode()
																	? "text-secondary "
																	: "text-primary") + " p-0 m-0"
															}
														>
															Local
														</h5>
														<p
															className={
																"text-primary p-0 m-0"
															}
														>
															{cursoSelecionado.Informacoes.Local.Value}
														</p>
													</Col>
													{cursoSelecionado.Informacoes.Local.Value ==
														"Polos" && (
														<Col className="col-12 pt-4 p-0 m-0">
															<Link to="/polos">
																<MDBBtn
																	size="sm"
																	block={true}
																	color="indigo"
																	style={{color:"#303030"}}
																	className={
																		GGgetMode()
																			? "border border-secondary"
																			: ""
																	}
																>
																	Conheça os polos
																</MDBBtn>
															</Link>
														</Col>
													)}
												</Row>
											</MDBCardBody>
										</MDBCard>
									</Col>
								</Row>
							</Col>

							<Col className="col-12 pt-md-4 pb-4  px-3 m-0 order-1 order-md-2">
								<Link to={`/cursos/${curso.Slug}/disciplinas${hash}`}>
									{/*<MDBBtn
										block={true}
										color="indigo"
										className={
											GGgetMode()
												? "text-white border border-secondary"
												: "text-white"
										}
									>
										Conheça a Estrutura Curricular
									</MDBBtn>*/}
								</Link>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					{diferenciais && (
						<Col className="col-12 col-md-6 pt-3">
							<h4 className="text-secondary">Diferenciais do curso</h4>
							<ul className={GGgetMode() && "text-white"}>
								{diferenciais.map((i) => {
									if (i.diferencialUrl) {
										return (
											<li>
												<a href={i.diferencialUrl} target="_blank">
													{i.diferencialNome}
												</a>
											</li>
										);
									}
									return <li>{i.diferencialNome}</li>;
								})}
							</ul>
						</Col>
					)}

					{projetos && (
						<Col className="col-12 col-md-6 pt-3">
							<h4 className="text-secondary">Projetos do curso</h4>
							<ul className={GGgetMode() && "text-white"}>
								{projetos.map((i) => {
									if (i.uenUrl) {
										return (
											<li>
												<a href={i.uenUrl} target="_blank">
													{i.uenNome}
												</a>
											</li>
										);
									}
									return <li>{i.uenNome}</li>;
								})}
							</ul>
						</Col>
					)}

					{diferenciaisGerenciador && (
						<Col className="col-12 col-md-6 pt-3">
							<h4 className="text-secondary">Diferenciais do curso</h4>
							<p
								className={GGgetMode() && "text-white"}
								dangerouslySetInnerHTML={{
									__html: diferenciaisGerenciador,
								}}
							/>
						</Col>
					)}

					{parceirosDeMercado && (
						<Col className="col-12 col-md-6 pt-3">
							<h4 className="text-secondary">Parceiros do mercado</h4>
							<p
								className={GGgetMode() && "text-white"}
								dangerouslySetInnerHTML={{
									__html: parceirosDeMercado,
								}}
							/>
						</Col>
					)}

					{internacionalizacao && (
						<Col className="col-12 col-md-6 pt-3">
							<h4 className="text-secondary">Parceiros internacionais</h4>
							<p
								className={GGgetMode() && "text-white"}
								dangerouslySetInnerHTML={{
									__html: internacionalizacao,
								}}
							/>
						</Col>
					)}
				</Row>
				{ConteudoAdicional && (
					<Row>
						<Col>
							<h4 className="text-secondary">Mais sobre o curso</h4>
							<div
								className={GGgetMode() && "white-text"}
								dangerouslySetInnerHTML={{
									__html: ConteudoAdicional,
								}}
							/>
						</Col>
					</Row>
				)}
			</Container>
			{GGgetMode() && <hr className="bg-secondary mt-5" />}
			<Container
				fluid
				className={(GGgetMode() ? "bg-primary" : "mt-5") + " pt-5 "}
				style={{ backgroundColor: "#EBECF0" }}
			>
				<Container>
					<Row className="no-gutters">
						<Col className="no-gutters">{getValor()}</Col>
					</Row>
					<Row
						className="text-center"
						style={{
							transform: GGgetMode() ? "translateY(70%)" : "translateY(50%)",
						}}
					>
						<Col md="12">
							<Link to="/inscrevase">
								<ButtonCTA key="btnInscBottom" block={false} />
							</Link>
						</Col>
					</Row>
				</Container>
			</Container>
			{GGgetMode() && <hr className="bg-secondary" />}
			{categorias && (
				<>
					<Container
						fluid
						className="bg-secondary py-5 text-white text-center text-md-left"
					>
						<Container>
							<Row>
								<Col>
									<h2 className="text-center font-weight-bold py-4 mt-3">
										Perfil do egresso
									</h2>
								</Col>
							</Row>
							<Row>
								{categorias.map((categoria) => {
									return (
										<Col className={"col-12 col-md-" + 12 / categorias.length}>
											<h4 className="text-white font-weight-bold py-3">
												{categoria.cursoPerfilDoEgressoCategoriaNome}
											</h4>
											{habilidades.map((habilidade) => {
												if (
													habilidade.cursoPerfilDoEgressoCategoriaId ==
													categoria.cursoPerfilDoEgressoCategoriaId
												) {
													return (
														<p>
															<span className="font-weight-bold">
																{habilidade.cursoPerfilDoEgressoHabilidadeNome}:
															</span>{" "}
															<span>
																{
																	habilidade.cursoPerfilDoEgressoHabilidadeDescricao
																}
															</span>
														</p>
													);
												}
											})}
										</Col>
									);
								})}
							</Row>
						</Container>
					</Container>
					{GGgetMode() && <hr className="bg-secondary" />}
				</>
			)}
		</InnerLayout>
	);
}
